const Cancel = () => {
    return (
      <div>
        <h1>Cancel</h1>
        <h2>Your payment was canceled.</h2>
  <a href="/">Go back to home</a>
      </div>
    );
  };
  
  export default Cancel;
  