import React, { useState, useEffect } from 'react';
import logo from '../images/psc-logo.svg';
import { Footer } from '../components/footer';

const Contact = () => {

    useEffect(() => {
        const script = document.createElement('script');
        script.src='https://js.hsforms.net/forms/embed/v2.js';
        document.body.appendChild(script);

        var css = `.hub-spot-form > input{
          border: 1px solid #979797!important;
          border-top-left-radius: 4px!important;
          border-bottom-left-radius: 4px!important;
          border-top-right-radius: 0!important;
          border-bottom-right-radius: 0!important;
          padding-left: 16px!important;
          font-size: 20px!important;
          transition: all 0.3s ease;
      }
      .submit-btn-new{
          background: #00A7CE !important;
          font-size: 18px!important;
          font-weight: 700!important; 
          color: rgb(33,37,41)!important;
          `;

        const styles = document.createElement('style');
        styles.type = 'text/css';
        styles.appendChild(document.createTextNode(css));

        script.addEventListener('load', () => {
            // @TS-ignore
            if (window.hbspt) {
                // @TS-ignore
                window.hbspt.forms.create({
                    portalId: '5691769',
                    formId: '79b7e2fe-6836-460c-b6ae-38104eff7542',
                    target: '#hubspotForm',
                    // cssClass:"hub-spot-form",
                    // submitButtonClass:"submit-btn-new",
                    // css:"",
                })
            }
        });
    }, []);

    useEffect(() => {
        setTimeout(() => {
                document.body.classList.add('animate');
            }, 250);
      }, [])

    return (
        <div className="App results-page">

        <div className="container">
  
          <div className="row">
            <div className="app-header-border"></div>
  
            <div className="feature-section d-flex position-relative">
  
              <div className="col-12 col-md-8 col-lg-6 feature-text ">
                <header className="App-header">
                  <a className="App-home" href="/">
                    <img src={logo} className="App-logo" alt="logo" />
                  </a>
                </header>
              </div>
            </div>
          </div>
          <div className="row">
            <div class="contact col-xl-4 offset-xl-4 col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-12">
            <div id="hubspotForm"></div>
          </div>
            
            
      
        </div>
        </div>
        <Footer />
         {/* <style>
            {`.hub-spot-form > input{
            border: 1px solid #979797!important;
            border-top-left-radius: 4px!important;
            border-bottom-left-radius: 4px!important;
            border-top-right-radius: 0!important;
            border-bottom-right-radius: 0!important;
            padding-left: 16px!important;
            font-size: 20px!important;
            transition: all 0.3s ease;
        }
        .submit-btn-new{
            background: #00A7CE !important;
            font-size: 18px!important;
            font-weight: 700!important; 
            color: rgb(33,37,41)!important;
            `}
            </style> */}
        </div>
    );




    
  };
  
  export default Contact;
  