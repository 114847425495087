import React, { Component } from 'react';
import formativeLogo from '../images/formative.svg';

export class Footer extends Component {
    static displayName = Footer.name;

    constructor(props) {
      super(props);
    }

    componentDidMount() {

    }
  
  render () {

    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <img src={formativeLogo} className="" alt="formative" />
                    </div>
                    
                    <div className="col ml-auto">
                        <div className="links d-flex flex-wrap justify-content-end align-items-center">
                        <a
                            className="mr-2"
                            href="/contact">
                            Contact Us
                        </a>
                            <a
                            className="mr-2"
                            href="/faq">
                            FAQs
                        </a>
                        <a
                            className=""
                            href="https://formativeco.com/privacy"
                            target="_blank">
                            Privacy
                        </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
  }
}
