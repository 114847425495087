import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from 'react-router-dom';


let stripePromise;


const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  }

  return stripePromise;
};

const Checkout = ({ returnId, url, domainTotal, cost, sitemaps }) => {

  const navigate = useNavigate();
  const [stripeError, setStripeError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [idVal, setIdVal] = useState('');
  const id = returnId
  const item = {
    price: returnId,
    quantity: 1,
  }
  

  
  
  const freeCheckout = async () => {
    await fetch('https://t8f50s3ma5.execute-api.us-west-2.amazonaws.com/get-results', { 
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
        body: JSON.stringify({
          url: url,
          cost: cost,
          sitemaps: sitemaps,
          urls:domainTotal
          })
        })
        .then(response => response.json())
    .then(res => {
      
      setLoading(true);
      setLoading(false);
      console.log(res['created']);
      if(res['created']){
        setIdVal(res['id']);
        navigate(`/results?jid=${res['id']}`);
      }else{
        console.log("error");
      }
    })
    .catch(function(err) {
        console.info(err + " url: " + url);
    });
     
    
    
    };

    const redirectToCheckout = async () => {
      setLoading(true);
      await fetch('https://t8f50s3ma5.execute-api.us-west-2.amazonaws.com/get-results', { 
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
        body: JSON.stringify({
          url: url,
          cost: cost,
          sitemaps: sitemaps,
          urls:domainTotal
          })
        })
        .then(response => response.json())
    .then(res => {
      setIdVal(res['id']);
      if(res['created']){
        setLoading(false);
        redirectNow(res['id']);
      }else{
        console.log("error");
      }
    })
    
    .catch(function(err) {
        console.info(err + " url: " + url);
    });

    };

  const redirectNow = async (id,date) => {
    setLoading(true);
    const checkoutOptions = {
      lineItems: [item],
      mode: "payment",
      successUrl: `${window.location.origin}/success?jid=${id}&tid={CHECKOUT_SESSION_ID}`,
      cancelUrl: `${window.location.origin}/`
    };

    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout(checkoutOptions);
    console.log("Stripe checkout error", error);

    if (error) setStripeError(error.message);
    setLoading(false);
  };

  if (stripeError) alert(stripeError);

  return (
    <div className="checkout"> 
      <button
        className="btn btn-blue mt-2 text-uppercase"
        onClick={domainTotal <= 50 ?  freeCheckout : redirectToCheckout}
        disabled={isLoading}
      >
       {isLoading ? "Loading..." : "Get My Results"}
        
      </button>
    </div>
  );
};

export default Checkout;
