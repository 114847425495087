import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


const Success = () => {
    const queryParams = new URLSearchParams(window.location.search)
    const id = queryParams.get("jid")
    const paymentId = queryParams.get("tid")
    
    const navigate = useNavigate();

    const freeCheckout = async () => {
        await fetch('https://t8f50s3ma5.execute-api.us-west-2.amazonaws.com/success', { 
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
            body: JSON.stringify({
              id: id,
              paymentId: paymentId,
             
              })
            })
            .then(response => response.json())
            .then(res => {
            console.log(res)
            if(res['created']){
                
                navigate(`/results?jid=${id}`);
            }else{
                console.log("errors");
            }
            })
            .catch(function(err) {
                console.info(err + " url: " + paymentId);
            });
         
        
        
        };


    useEffect(() => {
        freeCheckout();
    }, [])
  return (
    <div>
      <p>loading .... </p>
    </div>
  );
};

export default Success;
