import React, { Component } from 'react';
import {BrowserRouter as Router, useRoutes} from "react-router-dom";
import './App.css';
import { Dashboard } from './pages/index';
import { FAQ } from './pages/faq';
import { Privacy } from './pages/privacy';

import Results from "./components/Results";
import Cancel from "./components/Cancel";
import Success from "./components/Success";
import Contact from "./components/Contact";


const Component1 = () => {
  return ''
};

const Component2 = () => {
  return <h1>Component 2</h1>;
};

const App = () => {
  let routes = useRoutes([
    { path: "/", element: <Dashboard /> },
    { path: "/results", element: <Results /> },
    { path: "/success", element: <Success /> },
    { path: "/cancel", element: <Cancel /> },
    { path: "/faq", element: <FAQ /> },
    { path: "/privacy", element: <Privacy /> },
    { path: "/contact", element: <Contact /> },
  ]);
  return routes;
};

const AppWrapper = () => {
  return (
    <Router>
      <App />
      <Component1 />
    </Router>
  );
};

export default AppWrapper;
