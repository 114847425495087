import React, { Component } from 'react';
import { useState } from "react";
import logo from '../images/psc-logo.svg';
import xmlIcon from '../images/xml-icon.png';
import successIcon from '../images/success.png';
import formativeLogo from '../images/formative.svg';
import { ReactComponent as GraphicSvg } from '../images/graphic.svg';
import { Footer } from '../components/footer';
import Checkout from "../components/Checkout";



export class Dashboard extends Component {
    static displayName = Dashboard.name;

    constructor(props) {
      super(props);
      this.state = {
        loading: true,
        loadingSite: false,
        url: null,
        validUrl: true,
        domainTotal: 0,
        returnData: null,
        returnStatus: 'Pending',
        timeEstimate: null,
        returnCost: 0.00,
        returnId:null,
        sitemaps: []
      };
    }

    componentDidMount() {

        setTimeout(() => {
            document.body.classList.add('animate');
        }, 250);

        this.setState({
            loading: false
        });
    }

    urlChange = (event) => {
        this.setState({
            url: event.target.value
        })
    };

    handleSubmit = (e) => {
        e.preventDefault();
        // setSubmitted(true);
        console.log("TEST")
        console.log(this.state.url);
        let res;
    
        if(this.state.url != null){
          res = this.state.url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        } else {
          this.setState({
              validUrl: false
          });
      }
        if(res != null) {

          this.setState({
            validUrl: true,
            loadingSite: true,
            returnStatus: 'Processing sitemap...'
          });

          let data = '';
          
          if(!this.state.url.includes('https://') && !this.state.url.includes('http://')) {
             data = `{
              "url": "https://${this.state.url.toLowerCase()}"
            }`
          } else {
            data = `{
              "url": "${this.state.url.toLowerCase()}"
            }`
          }
    
      
          fetch('https://t8f50s3ma5.execute-api.us-west-2.amazonaws.com/url-count', {
            method: 'post',
            body: data,
            headers: { 'Content-Type': 'application/json' }
            })
            .then(res => res.json())
              
            .then((json) => {

              //if (("Error" in json) === "True" || ("message" in json) === "Internal Server Error"){
            if (json.message === "Internal Server Error"){
              this.setState({
                validUrl: false,
                loadingSite: false,
                domainTotal: 0,
                returnStatus: "Sorry, we couldn't use that URL.",
              });

              }else{
                let urlCount = json['TotalCount'];
                let time = urlCount*0.7;
                time = Math.ceil(time/60);

                this.setState({
                    validUrl: true,
                    loadingSite: false,
                    returnStatus: 'Successfully Retrieved',
                    domainTotal: json['TotalCount'],
                    returnStatus: json['Status'],
                    timeEstimate: time,
                    returnCost: json['Cost'],
                    returnId: json['PriceId'],
                    sitemaps: json['Sitemaps']
                });

                localStorage.setItem('domainTotal', json['TotalCount']);
    
              }
            })
            .catch((error) => {
              this.setState({
                validUrl: true,
                loadingSite: false,
                domainTotal: 0,
                returnStatus: "Sorry, we couldn't use that URL.",
                //returnStatus: JSON.stringify(error),
            });
            })
            .finally(() => {
                this.setState({
                  loadingSite: false
                });
          });
        } else {
            this.setState({
                validUrl: false
            });
        }
    
      };
  
  render () {

  let contents = this.state.loading
  ? 
  <div className="loader-container d-flex flex-column h-100 p-4 text-center justify-content-center align-items-center">Loading...</div>
  :
    <div className="App">
      <div className="container">

        <div className="row">
          <div className="app-header-border"></div>

          <div className="feature-section d-flex position-relative">

            <div className="col-12 col-md-8 col-lg-6 feature-text">
              <header className="App-header">
                <a className="App-home" href="/">
                  <img src={logo} className="App-logo" alt="logo" />
                </a>
              </header>

              <h1 className="text-white mb-3">Enter a Site URL to Get Started</h1>

              <p className="text-white">This tool will extract the Sitemap(s) and check every link in the sitemap for a Status 200 (=page is up). If a page returns anything other than a Status 200, we'll display the failed URLs below</p>
            </div>

            <div className="col-12 col-md-4 offset-lg-1 d-flex graphic-container">
                <GraphicSvg/>
            </div>

          </div>

          <div className="form-section">
            <div className="form-section-container bg-off-white rounded d-flex flex-wrap">
            <div className="col-12 col-md-7 col-xl-6 position-relative">
              <div className="form bg-off-white d-flex align-items-center justify-content-center px-4 px-lg-5">
                {/* <form onSubmit={this.submitForm} className="d-flex flex-wrap p-4 w-100"> */}
                <form className="d-flex w-100" onSubmit={this.handleSubmit}>

                <label className="d-none">
                  Website URL
                </label>
                <input 
                    type="text" 
                    placeholder="Enter URL"
                    onChange={this.urlChange} 
                    // defaultValue={this.state.websiteUrl} 
                    className="flex-grow-1" />
                    <button className="btn btn-blue px-2 px-lg-4">
                      {!this.state.loadingSite &&
                        <span className="text-uppercase">Get Estimate</span>
                      }
                      {this.state.loadingSite &&
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      }
                    </button>
                </form>
                {!this.state.validUrl &&
                  <span className="error">Sorry, please enter a valid url.</span> 
                }
                <hr />
              </div>
              {/* {!this.state.domainTotal > 0 &&
                  <div className="estimate pt-4 pt-lg-5">
                    <p>A valid sitemap is required. Please note that there are many variations of sitemaps and we are in the process of adding functionality for all of</p>
                  </div>
                }  */}
              {/* {this.state.domainTotal > 0 && */}
              <div className="estimate pt-4 pt-lg-5">
                  <div className="d-flex">
                    <img src={xmlIcon} className="pr-3 xml-icon icon" alt="xml icon" />
                    <div className="estimate-details">
                      {this.state.returnStatus &&
                        <span className="h3 bold-700 text-uppercase">{this.state.returnStatus}</span>
                      }
                      {!this.state.returnStatus &&
                        <span className="h3 bold-700 text-uppercase">Pending</span>
                      }
                    </div>
                  </div>
                  <div className="d-flex text-green d-flex align-items-end py-4">
                    <span className="h3 bold-700 url-count mb-0">{this.state.domainTotal}</span> 
                    <span className="h4 bold-700 pl-4">URLs pending for status check.</span>
                  </div>
                  <div className="d-flex align-items-end">
                    <h4>Total Cost:</h4> 
                    <span className="h3 bold-700 cost">${this.state.returnCost}</span>
                  </div>
                  
                  <p>Estimated time for completion: 
                    {this.state.timeEstimate !== 0 &&
                      <span className="text-green bold-700">~{this.state.timeEstimate}min (@ 2 URLs/ sec)</span>
                    }
                    
                    
                  </p>
                  
                  <Checkout returnId={this.state.returnId} url={this.state.url} domainTotal={this.state.domainTotal} cost={this.state.returnCost} sitemaps={this.state.sitemaps} />
                </div>
                {/* } */}
            </div>
            

            <div className="col-12 col-md-5 offset-xl-1">
              <div className="cost-table p-3 px-md-4 p-lg-5 pt-4 pt-lg-5">
                <h5 className="bold-700">Cost Table</h5>
                  <table className="table bg-white p-4 mt-3">
                    <tbody>
                    <tr>
                      <th className="bold-700">No. or URLs</th>
                      <th className="bold-700">Cost</th>
                    </tr>
                    <tr>
                      <td>1-49</td>
                      <td>FREE</td>
                    </tr>
                    <tr>
                      <td>50-199</td>
                      <td>$2.99</td>
                    </tr>
                    <tr>
                      <td>200-499</td>
                      <td>$4.99</td>
                    </tr>
                    <tr>
                      <td>500-999</td>
                      <td>$9.99</td>
                    </tr>
                    <tr>
                      <td>every additional URL</td>
                      <td>$0.01</td>
                    </tr>
                    </tbody>
                  </table>
              </div>
            </div>
            </div>
          </div>

              {this.state.domainTotal > 10000000000000 &&
              <div className="results-section mt-4 mt-md-5">
                <div className="results-section-container bg-off-white p-4 p-md-5 d-flex flex-wrap rounded">
                  <div className="col-12 mb-4 mb-md-5">
                    <div className="d-flex">
                        <img src={successIcon} className="pr-3 xml-icon icon" alt="xml icon" />
                        <div className="result-details">
                          {/* {returnStatus &&
                            <span className="h3 bold-700 text-uppercase">{returnStatus}</span>
                          }
                          {!returnStatus &&
                            <span className="h3 bold-700 text-uppercase">Pending</span>
                          } */}
                          <span className="h3 bold-700 text-uppercase">Scan Results</span>
                        </div>
                      </div>
                  </div>
                  <div className="col-12 col-md-5">
                    <div className="d-flex text-green d-flex flex-column align-items-start py-4">
                      <span className="h3 bold-700 large-number mb-2 large-number">0</span> 
                      <span className="h4 bold-700 pl-4">URLs are healthy</span>
                      <p className="text-dark large italic">Returned a status of 200</p>
                    </div>
                  </div>
                  <div className="col-12 col-md-1 border-right"></div>
                  <div className="col-12 col-md-5 offset-md-1">
                    <div className="d-flex text-red d-flex flex-column align-items-start py-4">
                      <span className="h3 bold-700 large-number mb-2">0</span> 
                      <span className="h4 bold-700 pl-4">URLs returned errors</span>
                      <p className="text-dark large italic">See the table below for a list of errors</p>
                    </div>
                  </div>
                </div>
              </div>
              }
            {this.state.domainTotal > 1000000000 &&
            <div className="errors-section rounded off-white">
              <div className="error-section-container bg-off-white p-4 p-md-5 d-flex flex-wrap rounded position-relative">
                <div className="col-12 d-flex align-items-center justify-content-between">
                  <span className="h4 bold-700">Detected page errors for {this.state.url}</span>
                  <button className="btn btn-blue ml-auto text-uppercase">Download CSV</button>
                </div>
                <div className="col-12">
                <table className="table bg-white p-4 mt-4">
                    <tbody>
                    <tr>
                      <th className="bold-700">Date & Time</th>
                      <th className="bold-700">URL</th>
                      <th className="bold-700">Status</th>
                    </tr>
                    <tr>
                      <td>2022-08-12 at 02:15:52</td>
                      <td>https://www.acme.com/abc/xyz/asdf</td>
                      <td>502</td>
                    </tr>
                    <tr>
                      <td>2022-08-12 at 02:15:52</td>
                      <td>https://www.acme.com/abc/xyz/asdf</td>
                      <td>404</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            }
        </div>
      </div>
      <Footer />
      
    </div>

    return (
      <div className="container-fluid px-0">
            {contents}
      </div>
    );
  }
}
